import { DKButton, DKIcon, DKLabel } from "deskera-ui-library";
import { Component } from "react";

import ic_profile from "../assets/ic_profile.jpg";

import ic_facebook from "../assets/social/ic_facebook.png";
import ic_instagram from "../assets/social/ic_instagram.png";
import ic_twitter from "../assets/social/ic_twitter.png";
import ic_linkedin from "../assets/social/ic_linkedin.png";

class Footer extends Component {
  render() {
    return (
      <div
        className="row justify-content-between parent-width bg-white align-items-start p-h-l"
        style={{ marginTop: 50 }}
      >
        <div className="row width-auto flex-wrap align-items-start">
          <DKIcon src={ic_profile} className="ic-m circle" />
          <div className=" ml-r">
            <DKLabel text="Piyush Kandrikar" className="fw-m fs-l mr-xxl" />
            <DKLabel
              text="Product Engineering | Web & Mobile Development | UI/UX Enthusiast"
              className="text-gray mt-xs"
            />
          </div>
        </div>
        <div className="row width-auto flex-wrap align-items-start">
          <DKButton
            icon={ic_linkedin}
            className="opacity-6"
            onClick={() => {
              window.open(
                "https://www.linkedin.com/in/piyush-kandrikar-7155a865/",
                "_self"
              );
            }}
          />
          <DKButton
            icon={ic_facebook}
            className="opacity-6"
            onClick={() => {
              window.open("https://www.facebook.com/piyush.kandrikar", "_self");
            }}
          />
        </div>
      </div>
    );
  }
}

export default Footer;
