import { DKButton, DKIcon, DKIcons, DKLabel, DKLine } from "deskera-ui-library";
import { Component } from "react";

import ic_whatsapp from "../assets/social/ic_whatsapp.png";

class ContactPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="transparent-background">
        <div
          className="border-radius-m bg-white column shadow-m popup-window p-0"
          style={{ width: 450, maxWidth: "90%", paddingBottom: 25 }}
        >
          <div className="row justify-content-between pl-xl pr-m p-v-r">
            <DKLabel text="Contact Me" className="fw-m   fs-l" />
            <DKButton
              icon={DKIcons.ic_close}
              className="ic-s"
              onClick={() => this.props.onClose()}
            />
          </div>
          <DKLine className="mb-r" />
          {this.getInfoRow(
            DKIcons.ic_linkedin,
            "LinkedIn Profile",
            "https://linkedin.com/in/piyush-kandrikar",
            true
          )}
          {this.getInfoRow(
            DKIcons.ic_email,
            "Email Address",
            "pkandrikar@gmail.com"
          )}
          {this.getInfoRow(DKIcons.ic_phone, "Phone Number", "+65 9350 9479")}
          {/* {this.getInfoRow(ic_whatsapp, "WhatsApp", "+91 80073 23625")} */}
          {this.getInfoRow(
            DKIcons.ic_location,
            "Location",
            "Singapore & India"
          )}
          {this.getInfoRow(
            DKIcons.ic_webhook,
            "Website",
            "https://www.piyushkandrikar.com",
            true
          )}
        </div>
      </div>
    );
  }
  getInfoRow(icon, title, value, isLink = false) {
    return (
      <div className="row  p-h-xxl  p-v-m align-items-start">
        <DKIcon src={icon} className="ic-s-2" />
        <div className="ml-l">
          <DKLabel text={title} className=" fw-m" style={{ width: 150 }} />
          <div
            className={isLink ? " text-underline cursor-hand" : ""}
            onClick={() => {
              if (isLink) {
                window.open(value);
              }
            }}
          >
            <DKLabel text={value} className="mt-r text-dark-gray" />
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPopup;
