import { DKLabel } from "deskera-ui-library";
import { Component } from "react";

class Header extends Component {
  render() {
    return (
      <div
        className="column parent-width bg-dark-gray align-items-center justify-content-center grad"
        style={{ height: 50, top: 0, left: 0 }}
      >
        <DKLabel
          text="Innovate, implement, inspire. Keep coding!"
          className="fw-m text-white"
        />
      </div>
    );
  }
}

export default Header;
